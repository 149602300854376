import React from 'react';  
import ProfileGallery from './components/ProfileGallery';  
import './styles/tailwind.css';  

const App = () => {  
    return (  
        <div>  
            <ProfileGallery />  
        </div>  
    );  
};  

export default App;