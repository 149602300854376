import React, { useState, useEffect, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';

// Import images with correct relative paths  
import ampliacion1 from '../images/Ampliación de Panadería/1.webp';
import ampliacion2 from '../images/Ampliación de Panadería/2.webp';
import asfaltado1 from '../images/Asfaltado Público/1.webp';
import asfaltado2 from '../images/Asfaltado Público/2.webp';
import asfaltado3 from '../images/Asfaltado Público/3.webp';
import replanteo from '../images/Replanteo de Terreno/1.webp';
import vaciado1 from '../images/Vaciado de Aceras y Brocales/1.webp';
import vaciado2 from '../images/Vaciado de Aceras y Brocales/2.webp';
import vaciado3 from '../images/Vaciado de Aceras y Brocales/3.webp';

const translations = {
    en: {
        title: "Maria Hidalgo",
        gallery: "My Work",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        placeholders: [
            "Bakery Expansion [1/2]",
            "Bakery Expansion [2/2]",
            "Public Paving [1/3]",
            "Public Paving [2/3]",
            "Public Paving [3/3]",
            "Land Re-plotting [1/1]",
            "Excavation of Sidewalks and Curbs [1/3]",
            "Excavation of Sidewalks and Curbs [2/3]",
            "Excavation of Sidewalks and Curbs [3/3]",
        ],
        civilEngineer: "Civil Engineer",
        contact: "Contact",
    },
    es: {
        title: "Maria Hidalgo",
        gallery: "Mi Trabajo",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        placeholders: [
            "Ampliación de Panadería [1/2]",
            "Ampliación de Panadería [2/2]",
            "Asfaltado Público [1/3]",
            "Asfaltado Público [2/3]",
            "Asfaltado Público [3/3]",
            "Replanteo de Terreno [1/1]",
            "Vaciado de Aceras y Brocales [1/3]",
            "Vaciado de Aceras y Brocales [2/3]",
            "Vaciado de Aceras y Brocales [3/3]",
        ],
        civilEngineer: "Ingeniero Civil",
        contact: "Contacto",
    },
    pt: {
        title: "Maria Hidalgo",
        gallery: "Meu Trabalho",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        placeholders: [
            "Ampliação da Padaria [1/2]",
            "Ampliação da Padaria [2/2]",
            "Asfaltado Público [1/3]",
            "Asfaltado Público [2/3]",
            "Asfaltado Público [3/3]",
            "Replanteio de Terreno [1/1]",
            "Despejo de Calçadas e Brocais [1/3]",
            "Despejo de Calçadas e Brocais [2/3]",
            "Despejo de Calçadas e Brocais [3/3]",
        ],
        civilEngineer: "Engenheiro Civil",
        contact: "Contato",
    },
    zh: {
        title: "玛丽亚·伊达尔戈",
        gallery: "我的作品",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        placeholders: [
            "面包店扩建 [1/2]",
            "面包店扩建 [2/2]",
            "公共铺路 [1/3]",
            "公共铺路 [2/3]",
            "公共铺路 [3/3]",
            "土地重划 [1/1]",
            "人行道和路边的挖掘 [1/3]",
            "人行道和路边的挖掘 [2/3]",
            "人行道和路边的挖掘 [3/3]",
        ],
        civilEngineer: "土木工程师",
        contact: "联系",
    },
};

const ProfileGallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [darkMode, setDarkMode] = useState(false);
    const [language, setLanguage] = useState(navigator.language.slice(0, 2));
    const ref = useRef(null);

    const images = [
        {
            src: ampliacion1,
            placeholder: translations[language].placeholders[0],
        },
        {
            src: ampliacion2,
            placeholder: translations[language].placeholders[1],
        },
        {
            src: asfaltado1,
            placeholder: translations[language].placeholders[2],
        },
        {
            src: asfaltado2,
            placeholder: translations[language].placeholders[3],
        },
        {
            src: asfaltado3,
            placeholder: translations[language].placeholders[4],
        },
        {
            src: replanteo,
            placeholder: translations[language].placeholders[5],
        },
        {
            src: vaciado1,
            placeholder: translations[language].placeholders[6],
        },
        {
            src: vaciado2,
            placeholder: translations[language].placeholders[7],
        },
        {
            src: vaciado3,
            placeholder: translations[language].placeholders[8],
        },
    ];

    useEffect(() => {
        AOS.init({ duration: 1000 });
        const savedDarkMode = localStorage.getItem("darkMode") === "true";
        setDarkMode(savedDarkMode);
    }, []);

    useEffect(() => {
        localStorage.setItem("darkMode", darkMode);
        document.body.classList.toggle("dark", darkMode);
    }, [darkMode]);

    useEffect(() => {
        const userLang = navigator.language.slice(0, 2);
        if (translations[userLang]) {
            setLanguage(userLang);
        } else {
            setLanguage("en");
        }
    }, []);

    const openModal = (image, index) => {
        setSelectedImage(image);
        setSelectedImageIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage("");
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const toggleDarkMode = () => {
        setDarkMode((prev) => !prev);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const handleContact = () => {
        window.location.href = "mailto:info@mariahidalgo.homes";
    };

    return (
        <div className={`flex flex-col items-center justify-center min-h-screen p-4 relative ${darkMode ? "text-white bg-gray-900" : "text-black bg-gray-100"}`}>


            <div className="flex absolute top-4 right-4 z-50 space-x-4" data-aos="fade-in">
                <button onClick={toggleDarkMode} className={`flex items-center justify-center w-10 h-10 rounded-full ${darkMode ? "bg-gray-700" : "bg-gray-300"} transition duration-300 z-50`}>
                    {darkMode ? "🌙" : "☀️"}
                </button>
                <select value={language} onChange={handleLanguageChange} className={`rounded-full px-2 pr-0 py-2 ${darkMode ? "text-white bg-gray-800" : "bg-gray-200"} z-50`}>
                    <option value="en">English</option>
                    <option value="es">Español</option>
                    <option value="pt">Português</option>
                    <option value="zh">中文</option>
                </select>
            </div>

            <div
                className="overflow-hidden fixed inset-0 z-0 opacity-50 dark:opacity-10"
                style={{
                    backgroundImage: `url('/bg.svg')`,
                    backgroundRepeat: 'repeat',
                    backgroundSize: '200px',
                    backgroundPosition: 'center',
                    transform: 'rotate(-10deg) scale(2)',
                }}
            >
            </div>

            <div className="mb-6 text-center" ref={ref} data-aos="fade-up">
                <div className="z-40 mx-4 mt-16 max-w-2xl text-gray-900 bg-white rounded-lg shadow-xl sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto dark:bg-gray-800 dark:text-gray-300">
                    <div className="overflow-hidden h-32 rounded-t-lg">
                        <img className="object-cover object-top w-full" src='/banner.webp' alt='Construction' />
                    </div>
                    <div className="overflow-hidden relative z-40 mx-auto -mt-16 w-32 h-32 rounded-full border-4 border-white dark:border-gray-800">
                        <img className="object-cover object-center h-32" src='/maria.webp' alt='Woman looking front' />
                    </div>
                    <div className="z-40 mt-2 text-center">
                        <h2 className="text-2xl font-semibold" style={{ fontFamily: "'Dancing Script', cursive" }}>{translations[language].title}</h2>
                        <p className="text-gray-500 dark:text-gray-400">{translations[language].civilEngineer}</p>
                    </div>
                    <ul className="flex z-40 justify-around items-center py-4 mt-2 text-gray-700 dark:text-gray-400">
                        <li className="flex flex-col justify-around items-center transition-all duration-200 ease-in-out hover:scale-110">
                            <a href="https://www.instagram.com/mariahidalgo.homes/" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                <i className="text-[#C13584] dark:text-[#E1306C] icon-instagram" />  {/* Instagram colors */}
                                <span>{translations[language].instagram}</span>
                            </a>
                        </li>
                        <li className="flex flex-col justify-between items-center transition-all duration-200 ease-in-out hover:scale-110">
                            <a href="https://www.linkedin.com/in/mariahidalgohomes" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                <i className="text-[#0077B5] dark:text-[#0A66C2] icon-linkedin" />  {/* LinkedIn colors */}
                                <span>{translations[language].linkedin}</span>
                            </a>
                        </li>
                    </ul>
                    <div className="z-40 p-4 mx-8 mt-2 border-t">
                        <button onClick={handleContact} className="block px-6 py-2 mx-auto w-1/2 font-semibold text-white bg-gray-900 rounded-full transition-all duration-200 ease-in-out hover:shadow-lg dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-700">
                            {translations[language].contact}
                        </button>
                    </div>
                </div>
            </div>

            <h2 className={`z-30 mb-4 text-2xl font-black font-commissioner`} data-aos="fade-up"> {translations[language].gallery}</h2>
            <div className="flex z-30 flex-col items-center">
                <div className="grid z-30 grid-cols-2 gap-4 justify-center md:grid-cols-3 lg:grid-cols-4">
                    {images.map((image, index) => (
                        <div className="relative group" key={index} data-aos="fade-up">
                            <img
                                src={image.src}
                                alt={`Gallery ${index + 1}`}
                                className={`object-cover z-30 w-full h-48 rounded-lg shadow-md transition-transform duration-300 transform cursor-pointer hover:scale-105`}
                                onClick={() => openModal(image.src, index)}
                            />
                            <div
                                className="flex absolute inset-0 z-30 justify-center items-center bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 cursor-pointer group-hover:opacity-100"
                                onClick={() => openModal(image.src, index)}
                            >
                                <span className="text-lg font-bold text-center text-white drop-shadow-md font-comissioner">{image.placeholder}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isOpen && (
                <div
                    className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-70"
                    onClick={handleOverlayClick}
                >
                    <div className="relative z-50 p-4 mx-4 w-full max-w-full bg-white rounded shadow-lg dark:bg-gray-900 sm:max-w-2xl" data-aos="zoom-in">
                        <button
                            onClick={closeModal}
                            className="flex absolute top-2 right-2 justify-center items-center w-7 h-7 text-white bg-red-500 rounded-full transition duration-300 hover:bg-red-600 focus:outline-none"
                            aria-label="Close modal"
                        >
                            &times;
                        </button>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            className="w-full h-auto max-h-[80vh] rounded-lg mx-auto"
                        />
                        <p className={`text-center mt-4 text-lg ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                            {images[selectedImageIndex]?.placeholder}
                        </p>
                    </div>
                </div>
            )}
            <Footer language={language} />
        </div>
    );
};

export default ProfileGallery;