import React from "react";

const translations = {
    en: {
        copyright: `© ${new Date().getFullYear()} · made with ❤️ by `,
        linkText: "Colibri Creative Web",
    },
    es: {
        copyright: `© ${new Date().getFullYear()} · hecho con ❤️ por `,
        linkText: "Colibri Creative Web",
    },
    pt: {
        copyright: `© ${new Date().getFullYear()} · feito com ❤️ por `,
        linkText: "Colibri Creative Web",
    },
    zh: {
        copyright: `© ${new Date().getFullYear()} · 由 ❤️ 制作 `,
        linkText: "Colibri Creative Web",
    },
};

function Footer({ language }) {
    return (
        <footer>
            <div className="max-w-6xl px-4 mx-auto mt-12 sm:px-6">

                {/* Bottom area */}
                <div className="flex py-4 border-t border-gray-200 md:items-center md:justify-center md:py-8">
                    {/* Copyright note */}
                    <div className="text-sm text-gray-600">
                        {translations[language].copyright}
                        <a
                            className="text-transparent bg-clip-text bg-gradient-to-b from-[#00a6ff] to-[#00ffff] hover:text-blue-600 hover:underline font-bold"
                            href="https://colibricreativeweb.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translations[language].linkText}
                        </a>
                        {/* <a  
              target="_blank"  
              rel="license"  
              href="http://creativecommons.org/licenses/by-nc-nd/4.0/"  
            >  
              <img  
                alt="Creative Commons License"  
                src="https://i.creativecommons.org/l/by-nc-nd/3.0/80x15.png"  
              />  
            </a> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;